<template>
  <div class="content-wrapper">
    <div class="page-header">
      <div class="page-header-content header-elements-md-inline">
        <div class="page-title d-flex">
          <h4>
            <span class="font-weight-semibold">Dashboard</span>
          </h4>
          <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>
      </div>
    </div>
    <div class="content pt-0">
      <DashboardGeneral />
	  </div>
  </div>
</template>
<script>

import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import _ from 'lodash'
import DashboardGeneral from './DashboardGeneral.vue'


export default {
 	extends: GlobalVue,
  	components:{DashboardGeneral},
	data() {
    	return {
			modalResume: false,
		}
	},
	methods: {},
}

</script>