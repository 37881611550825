<template>
<div>
  <div class="form-row mt-2">
    <div class="col-md-4 col-sm-4">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_1"><i class="fa-solid fa-bed"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataRanap.total }}</h3>
            <p>Total Pasien</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-4">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_2"><i class="fa-solid fa-venus"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataRanap.perempuan }}</h3>
            <p> Pasien Perempuan</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-4">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_3"><i class="fa-solid fa-mars"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataRanap.lakiLaki }}</h3>
            <p> Pasien Laki-laki</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="my-2">
        <div class="row align-items-center">
          <div class="col-md-auto">
            <h5 class="font-weight-semibold mb-0">Jumlah pasien per jenis rawat inap</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-3 col-sm-3">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_1"><i class="fa-solid fa-bed"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataPerJenis.UM }}</h3>
            <p>Jumlah Pasien Umum</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-3">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_2"><i class="fa-solid fa-bed"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataPerJenis.OB }}</h3>
            <p>Jumlah Pasien Obsgyn</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_3"><i class="fa-solid fa-bed"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataPerJenis.PE }}</h3>
            <p>Jumlah Pasien Perinatologi</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-3">
      <div class="card_statistic">
        <div class="row">
          <div class="col-md-3">
            <div class="shape_card bg_4"><i class="fa-solid fa-bed"></i></div>
          </div>
          <div class="col-md-9">
            <h3>{{ totalDataPerJenis.KR }}</h3>
            <p>Jumlah Pasien Kritis</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-12">
      <div class="card mb-2">
        <div class="card-header">
          <h5 class="card-title font-weight-semibold">Jumlah pasien per bangsal</h5>
        </div>
        <div class="row">
          <div class="col-md-6" v-for="(v, k) in totalDataPerBangsal" :key="`bngsl-${k}`">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr v-for="(dt, kdt) in v" :key="`bngsldt-${kdt}`">
                    <th style="width: 20%;">
                      <span class="font-weight-bold">{{ dt.name }}</span>
                    </th>
                    <td>{{ dt.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="my-2">
        <div class="row align-items-center">
          <div class="col-md-auto">
            <h5 class="font-weight-semibold mb-0">Statistik Pendaftaran Rawat Inap</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-md-auto mr-auto">
              <h5 class="card-title font-weight-semibold">Jumlah pendaftaran ranap</h5>
            </div>
            <div class="col-md-6">
              <div class="form-row justify-content-end">
                <div class="col-md-4 col-sm-4">
                  <v-select v-model="filterPendaftaran.filter_type" :options="filterPendaftaranTypeOptions" :reduce="v => v.value" label="label" @input="changePendaftaranFilterType()" />
                </div>
                <div class="col-md-6 col-sm-6 patientPicker" v-if="filterPendaftaran.filter_type == 'D'">
                  <div class="input-group justify-content-end">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="filterPendaftaran.dateRange" :opens="'left'" :ranges="datePickerConfig.ranges" @update="updateValuePendaftaran">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <div class="col-md-6 patientPicker" v-else>
                  <v-select v-model="filterPendaftaran.year" :options="totalPendaftaranRanap.years" :reduce="v => v.value" label="label" @input="getTotalPendaftaranRanap()">

                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart-container">
            <v-chart class="chart has-fixed-height" :option="optionPendaftaran" autoresize />
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card mb-2">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-md-5 col-sm-5 mr-auto">
              <h6 class="card-title font-weight-semibold">Jumlah pasien berdasarkan cara pembayaran</h6>
            </div>
            <div class="col-md-7 col-sm-7">
              <div class="form-row justify-content-end">
                <div class="col-md-12 patientPicker">
                  <div class="input-group justify-content-end">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="filterCaraPembayaran.dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValueCaraPembayaran">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart-container">
            <v-chart class="chart has-fixed-height" :option="optionPasienCaraPembayaran" autoresize />
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card mb-2">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col-md-5 col-sm-5 mr-auto">
              <h6 class="card-title font-weight-semibold">Jumlah pasien berdasarkan asalnya</h6>
            </div>
            <div class="col-md-7 col-sm-7">
              <div class="form-row justify-content-end">
                <div class="col-md-12 patientPicker">
                  <div class="input-group justify-content-end">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="filterAsal.dateRange" :opens="'left'" :ranges="datePickerConfig.ranges" @update="updateValueAsal">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart-container">
            <v-chart class="chart has-fixed-height" :option="optionPasienAsal" autoresize />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-12">
      <div class="card mb-2">
        <div class="card-header">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-8">
              <h5 class="card-title font-weight-semibold">Cara keluar pasien </h5>
            </div>

            <div class="col-md-4">
              <div class="input-group justify-content-end">
                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span></div>
                <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="filterCaraKeluar.dateRange" :opens="'left'" :ranges="datePickerConfig.ranges" @update="updateValueCaraKeluar">
                  <template v-slot:input="picker">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Meninggal</th>
                <th>Dirujuk</th>
                <th>APS</th>
                <th>Pulang sembuh</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ totalCaraKeluar.meninggal }}</td>
                <td>{{ totalCaraKeluar.rujuk }}</td>
                <td>{{ totalCaraKeluar.atas_permintaan_pasien }}</td>
                <td>{{ totalCaraKeluar.pulang }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import {
  use
} from 'echarts/core'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  PieChart
} from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  MarkAreaComponent
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
])

const moment = require('moment')
import _ from 'lodash'

export default {
  extends: GlobalVue,
  data() {
    return {
      mPoli: [],
      dataReg: [],
      loadingInvinite: false,
      totalData: 0,

      filterPendaftaranTypeOptions: [{
          label: "Harian",
          value: "D"
        },
        {
          label: "Bulanan",
          value: "M"
        },
      ],
      filterPendaftaran: {
        filter_type: "D",
        dateRange: {
          startDate: new Date(moment().subtract(7, 'days')),
          endDate: new Date(),
        },
        year: null
      },
      filterCaraPembayaran: {
        dateRange: {
          startDate: new Date(moment().subtract(7, 'days')),
          endDate: new Date(),
        },
      },
      filterAsal: {
        dateRange: {
          startDate: new Date(moment().subtract(7, 'days')),
          endDate: new Date(),
        },
      },
      filterCaraKeluar: {
        dateRange: {
          startDate: new Date(moment().subtract(7, 'days')),
          endDate: new Date(),
        },
      },

      totalDataRanap: {
        total: 0,
        perempuan: 0,
        lakiLaki: 0
      },
      totalDataPerJenis: {
        UM: 0,
        OB: 0,
        PE: 0,
        KR: 0
      },
      totalDataPerBangsal: [],
      totalPendaftaranRanap: {
        xAxis: [],
        data: [],
        years: []
      },
      totalPasienCaraPembayaran: [],
      totalPasienAsal: [],
      totalCaraKeluar: {
        meninggal: 0,
        atas_permintaan_pasien: 0,
        rujuk: 0,
        pulang: 0
      },

      datePickerConfig: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      },
      options: {
        penColor: "#c0f",
      },
      recognition: null,
      transcribedText: '',
      elemen: ''
    }
  },
  components: {
    DateRangePicker,
    VChart,
  },
  methods: {
    getTotalPasienCaraKeluar() {
      Gen.apiRest(
        "/do/" + "RanapDashboard", {
          data: {
            type: "get-total-pasien-cara-keluar",
            ...this.filterCaraKeluar
          }
        },
        "POST"
      ).then(res => {
        this.$set(this, 'totalCaraKeluar', res.data)
      })
    },

    getTotalPasienAsal() {
      Gen.apiRest(
        "/do/" + "RanapDashboard", {
          data: {
            type: "get-total-pasien-asal",
            ...this.filterAsal
          }
        },
        "POST"
      ).then(res => {
        this.$set(this, 'totalPasienAsal', res.data)
      })
    },

    getTotalPasienCaraPembayaran() {
      Gen.apiRest(
        "/do/" + "RanapDashboard", {
          data: {
            type: "get-total-pasien-cara-pembayaran",
            ...this.filterCaraPembayaran
          }
        },
        "POST"
      ).then(res => {
        this.$set(this, 'totalPasienCaraPembayaran', res.data)
      })
    },

    getTotalPendaftaranRanap() {
      Gen.apiRest(
        "/do/" + "RanapDashboard", {
          data: {
            type: "get-total-pendaftaran-ranap",
            ...this.filterPendaftaran
          }
        },
        "POST"
      ).then(res => {
        this.$set(this, 'totalPendaftaranRanap', res.data)
      })
    },

    getTotalPerBangsal() {
      Gen.apiRest(
        "/get/" + "RanapDashboard", {
          params: {
            type: "get-total-per-bangsal",
          }
        },
      ).then(res => {
        this.$set(this, 'totalDataPerBangsal', _.chunk(res.data.data, res.data.divider))
      })
    },

    getTotalPerJenisRanap() {
      Gen.apiRest(
        "/get/" + "RanapDashboard", {
          params: {
            type: "get-total-per-jenis-ranap",
          }
        },
      ).then(res => {
        this.$set(this, 'totalDataPerJenis', res.data)
      })
    },

    getTotalData() {
      Gen.apiRest(
        "/get/" + "RanapDashboard", {
          params: {
            type: "get-total-data",
          }
        },
      ).then(res => {
        this.$set(this, 'totalDataRanap', res.data)
      })
    },
    copy() {
      this.$refs.myinput.focus()
      document.execCommand('copy')
    },
    doPageOne() {
      this.filter.page = 1
    },

    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },

    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),

    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    updateValuePendaftaran() {
      this.filterPendaftaran.startDate = moment(this.filterPendaftaran.dateRange.startDate).format('YYYY-MM-DD')
      this.filterPendaftaran.endDate = moment(this.filterPendaftaran.dateRange.endDate).format('YYYY-MM-DD')

      setTimeout(() => {
        this.getTotalPendaftaranRanap()
      }, 0)
    },

    updateValueCaraPembayaran() {
      this.filterCaraPembayaran.startDate = moment(this.filterCaraPembayaran.dateRange.startDate).format('YYYY-MM-DD')
      this.filterCaraPembayaran.endDate = moment(this.filterCaraPembayaran.dateRange.endDate).format('YYYY-MM-DD')

      setTimeout(() => {
        this.getTotalPasienCaraPembayaran()
      }, 0)
    },

    updateValueAsal() {
      this.filterAsal.startDate = moment(this.filterAsal.dateRange.startDate).format('YYYY-MM-DD')
      this.filterAsal.endDate = moment(this.filterAsal.dateRange.endDate).format('YYYY-MM-DD')

      setTimeout(() => {
        this.getTotalPasienAsal()
      }, 0)
    },

    updateValueCaraKeluar() {
      this.filterCaraKeluar.startDate = moment(this.filterCaraKeluar.dateRange.startDate).format('YYYY-MM-DD')
      this.filterCaraKeluar.endDate = moment(this.filterCaraKeluar.dateRange.endDate).format('YYYY-MM-DD')

      setTimeout(() => {
        this.getTotalPasienCaraKeluar()
      }, 0)
    },

    changePendaftaranFilterType() {
      this.$set(this.filterPendaftaran, 'year', this.totalPendaftaranRanap.years[0])

      setTimeout(() => {
        this.getTotalPendaftaranRanap()
      }, 0)
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    apiGet() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query

      this.doConvertDate()

      if ((+this.$route.query.page || 1) == 1) {
        this.loadingTable = true
      }

      Gen.apiRest(
        "/get/" + this.modulePage +
        '/general', {
          params: Object.assign({
            page: this.filter.page,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate
          }, paramsQuery || {})
        }
      ).then(res => {
        let resp = res.data
        this.loadingTable = false
        this.loadingInvinite = false
        this.mPoli = resp.mPoli
        this.totalData = resp.dataReg.total

        if ((+this.$route.query.page || 1) !== 1) {
          let data = resp.dataReg.data
          for (let i = 0; i < (data || []).length; i++) {
            this.dataReg.push(data[i])
          }
        } else {
          this.dataReg = resp.dataReg.data
        }
      })
    },
    handleScroll(event) {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
        if ((this.dataReg || []).length < this.totalData && !this.loadingInvinite && !this.loadingTable) {
          this.loadingInvinite = true
          this.filter.page = (+this.$route.query.page || 1) + 1

          this.$router.push({
            name: this.modulePage,
            query: _.clone(this.filter)
          }).catch(() => {})
        }
      }
    },
    startListening() {
      if (!('webkitSpeechRecognition' in window)) {
        return this.$swal({
          icon: 'error',
          title: 'Web Browser Tidak Support dengan text to speech',
        })
      }

      this.recognition = new window.webkitSpeechRecognition

      this.recognition.lang = 'id-ID'
      this.recognition.interimResults = true
      this.recognition.continuous = true

      this.recognition.onresult = event => {
        let interimTranscript = ''
        let finalTranscript = ''

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript
          } else {
            interimTranscript += event.results[i][0].transcript
          }
        }

        this.transcribedText = finalTranscript
      }

      this.recognition.onerror = event => {
        console.error('Speech recognition error', event.error)
        this.stopListening()
      }

      this.recognition.onend = () => {
        this.stopListening()
      }

      this.recognition.start()
    },
    stopListening() {
      if (this.recognition) {
        this.recognition.stop()
        this.recognition = null
      }
    },
  },
  mounted() {
    this.filter.page = 1
    if (this.$route.query.page != 1) {
      let url = {
        name: this.$route.name,
        params: (this.$route.params || {}),
        query: Object.assign({}, this.$route.query || {}, _.clone(this.filter), {
          page: 1
        })
      }
      this.$router.push(url).catch(() => {})
    } else {
      this.apiGet()
    }

    this.getTotalData()
    this.getTotalPerJenisRanap()
    this.getTotalPerBangsal()
    this.getTotalPendaftaranRanap()
    this.getTotalPasienCaraPembayaran()
    this.getTotalPasienAsal()
    this.getTotalPasienCaraKeluar()
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    $route() {
      this.apiGet()
    }
  },
  computed: {
    optionPendaftaran() {
      return {
        color: ['#162c5e'],
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: this.totalPendaftaranRanap.xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: 'Jumlah Pendaftaran Rawat Inap',
          data: this.totalPendaftaranRanap.data,
          type: 'bar'
        }],
        grid: {
          left: 24,
          right: 15,
          top: 24,
          bottom: 24,
        }
      }
    },
    optionPasienCaraPembayaran() {
      return {
        color: ['#00a14c', '#1d4580', '#f54842'],
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [{
          name: 'Cara Pembayaran',
          type: 'pie',
          radius: '50%',
          data: this.totalPasienCaraPembayaran,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      }
    },
    optionPasienAsal() {
      return {
        color: ['#492E87', '#FF407D'],
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
            fontSize: 13,
            fontFamily: 'Roboto, sans-serif',
            color: '#fff'
          },
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [{
          name: 'Asal',
          type: 'pie',
          radius: '50%',
          data: this.totalPasienAsal,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      }
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  }

}
</script>
